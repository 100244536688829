import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import React from "react"
import { ToggleIcon, CustomLoader } from "../../components/custom/Icons"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import GoBack from "../../components/custom/Goback"
import { db } from "../../firebaseConfig"
import { doc, onSnapshot } from "firebase/firestore"
import { fetchBrandDetails } from "../../api/v2/brand"
import { fetchRestaurantInfo } from "../../api/v2/reservations"
import PersonIconNew from "../../components/SVG/PersonIconNew"
import CalendarIconNew from "../../components/SVG/CalendarIconNew"
import billDark from "../../assets/billDark.svg"
import { fetchBillInfo, fetchBlinqBillInfo } from "../../api/v2/bills"
import "./billStyle.css"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import SideMenu from "../sideMenu"
import FAQSUPPORTS from "../../components/TermsAndConditions/FAQS"
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions"
import tenantConfig from "../../tenantConfig.json"

const BillsInfo = () => {
  const nav = useNavigate()
  const location = useLocation()
  const isCard = location.pathname.includes("card")
  const { reservationID } = useParams()
  let [searchParams] = useSearchParams()
  const guestID = searchParams.get("id")
  const isInvited = searchParams.has("invited")
  const [portionPaid, setPortionPaid] = useState()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [statusPaid, setStatusPaid] = useState()
  const [showPaymentBtn, setShowPaymentBtn] = useState(true)
  const [isBillPayingPage, setIsBillPayingPage] = useState(true)
  const [menuIndex, setMenuIndex] = useState(1)

  const {
    data: blinqData,
    isError: onBlinqError,
    refetch: blinqRefetch,
  } = useQuery({
    queryKey: ["blinq-bill-info", reservationID],
    queryFn: () => fetchBlinqBillInfo(reservationID),
    retry: 3,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: isBillPayingPage && 25000,
    refetchIntervalInBackground: true,
    enabled: !!reservationID,
  })

  const {
    data: brandData,
    isLoading: brandLoading,
    refetch: brandRefetch,
  } = useQuery({
    queryKey: ["brand-info"],
    queryFn: () =>
      fetchBrandDetails(
        blinqData?.reservationData?.tenantId,
        blinqData?.reservationData?.subtenantId,
      ),
    retry: 3,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!blinqData?.reservationData?.tenantId,
  })

  const {
    data: restaurantData,
    isError: onRestaurantError,
    isLoading: restaurantLoading,
    refetch: restaurantRefetch,
  } = useQuery({
    queryKey: ["restaurant-info"],
    queryFn: () =>
      fetchRestaurantInfo(
        blinqData?.reservationData?.tenantId,
        blinqData?.reservationData?.subtenantId,
      ),
    retry: 3,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: isBillPayingPage && 30000,
    enabled: !!blinqData?.reservationData?.tenantId,
  })
  const {
    data: invoiceData,
    isLoading: invoiceDataLoading,
    isError: invoiceDataError,
    refetch: invoiceRefetch,
  } = useQuery({
    queryKey: ["bill-info", blinqData?.invoiceID],
    queryFn: () => fetchBillInfo(blinqData?.invoiceID),
    retry: 3,
    enabled: !!blinqData?.invoiceID,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: isBillPayingPage && 30000,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (reservationID) {
      sessionStorage.setItem("ReservationId", reservationID)
    }
  }, [reservationID])

  useEffect(() => {
    const isBillPage = !location.pathname.includes("split" || "card")
    const isGuestLocked = invoiceData?.guestsCountUpdated
    setIsBillPayingPage(isBillPage || !isGuestLocked)
  }, [location, invoiceData])

  useEffect(() => {
    if (!invoiceData) return
    if (!statusPaid) {
      setStatusPaid(invoiceData.bill_status === "paid")
    }
  }, [isInvited, guestID, nav, invoiceData])

  useEffect(() => {
    if (!invoiceRefetch || !reservationID || !blinqData?.invoiceID) return
    let ignoreFirstTime = true
    const query = doc(db, "reservations", reservationID)
    const invoiceSnapShot = onSnapshot(query, async (_doc) => {
      if (ignoreFirstTime) return (ignoreFirstTime = false)
      await invoiceRefetch()
    })
    return () => invoiceSnapShot()
  }, [invoiceRefetch, blinqData?.invoiceID, reservationID])

  useEffect(() => {
    if (reservationID) {
      blinqRefetch()
    }
  }, [reservationID, blinqRefetch])

  useEffect(() => {
    if (blinqData?.invoiceID) {
      invoiceRefetch()
    }
  }, [blinqData?.invoiceID, invoiceRefetch])

  useEffect(() => {
    if (blinqData?.reservationData?.tenantId) {
      restaurantRefetch()
      brandRefetch()
    }
  }, [blinqData?.reservationData?.tenantId, restaurantRefetch, brandRefetch])

  if (invoiceDataError || onBlinqError || onRestaurantError) {
    return (
      <div className="no-invoice-display">
        <h1 className="no-invoice-text">Invoice Not Found</h1>
      </div>
    )
  }
  if (
    restaurantData?.emergency_shutoff ||
    blinqData?.invoiceID === null ||
    invoiceData?.tables?.length === 0
  ) {
    return (
      <CustomFontComponent fontFamily={tenantConfig?.font ?? ""}>
        <div className={`item-body`}>
          <div className="item-outer-body">
            {toggleMenu && (
              <SideMenu
                toggleMenu={toggleMenu}
                toggleMenuHandler={() => setToggleMenu(false)}
                activeIndex={menuIndex}
                setMenuIndex={setMenuIndex}
              />
            )}
            <div className="item-top-section">
              <button
                onClick={() => setToggleMenu(!toggleMenu)}
                className="toggle-icon"
              >
                <ToggleIcon />
              </button>
              <img src={tenantConfig?.logo} alt="Zumi" className="logo-width" />
              {isCard && <GoBack color="black" />}
            </div>
            {menuIndex === 1 && (
              <div className="no-item-middle-section">
                <Accordion
                  allowZeroExpanded={true}
                  className="custom-accordion"
                >
                  <AccordionItem>
                    <AccordionItemButton>
                      <h6 className="bill-inner-view">
                        Bill #{blinqData?.reservationData?.reservationCode}
                      </h6>
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      <div className="guest-view">
                        <div>
                          <p className="guest-inner-view">
                            <PersonIconNew />
                            {blinqData?.reservationData?.guestsCount} guests
                          </p>
                          <p className="guest-inner-view">
                            <CalendarIconNew />
                            {new Date(
                              blinqData?.reservationData?.dateTime,
                            ).toDateString()}
                          </p>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <div className="bill-view">
                  <div className="no-item-bottom-section">
                    <div>
                      <div className="no-item-img-view">
                        <img
                          src={billDark}
                          alt="empty_bill"
                          className="no-item-img"
                        />
                      </div>
                      <p className="no-item-header-text">No Items</p>
                      <p className="no-item-desc-text">
                        Items will be added to your bill as you order.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {menuIndex === 2 && (
              <div className="flex w-full -mt-5">
                <FAQSUPPORTS />
              </div>
            )}
            {menuIndex === 3 && <TermsAndConditions />}
          </div>
        </div>
      </CustomFontComponent>
    )
  }
  return (
    <CustomFontComponent fontFamily={tenantConfig?.font ?? ""}>
      <div className={`item-body`}>
        <div className="item-outer-body">
          {toggleMenu && (
            <SideMenu
              toggleMenu={toggleMenu}
              toggleMenuHandler={() => setToggleMenu(false)}
              activeIndex={menuIndex}
              setMenuIndex={setMenuIndex}
            />
          )}
          <div className="item-top-section">
            <button
              onClick={() => setToggleMenu(!toggleMenu)}
              className="toggle-icon"
            >
              <ToggleIcon />
            </button>
            <img
              src={tenantConfig?.logo}
              alt="Zumi"
              className="logo-max-width"
            />
            {isCard && <GoBack color="black" />}
          </div>
          {menuIndex === 1 && (
            <div className="no-bill-view">
              {invoiceDataLoading || brandLoading || restaurantLoading ? (
                <div className="loader-fetching-text-view">
                  <CustomLoader />
                  <p className="loader-fetching-text">Fetching your bill...</p>
                </div>
              ) : (
                invoiceData &&
                blinqData?.invoiceID &&
                restaurantData && (
                  <Outlet
                    context={{
                      invoiceData: invoiceData,
                      isInvited,
                      guestID,
                      statusPaid,
                      setStatusPaid,
                      invoiceRefetch,
                      invoiceID: blinqData.invoiceID,
                      setShowPaymentBtn,
                      showPaymentBtn,
                      guestsNumber: restaurantData.max_splits,
                      setPortionPaid,
                      portionPaid,
                      restaurantInfo: restaurantData,
                      brandData,
                    }}
                  />
                )
              )}
            </div>
          )}
          {menuIndex === 2 && (
            <div className="flex w-full">
              <FAQSUPPORTS />
            </div>
          )}
          {menuIndex === 3 && <TermsAndConditions />}
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default BillsInfo
